class Venue {
    constructor(key, name, cover, location, age, address, city, state, zip) {
        this.key = key;
        this.name = name;
        this.cover = cover;
        this.location = location;
        this.age = age;
        this.address = address;
        this.city = city;
        this.state = state;
        this.zip = zip;
    }

    googleMap = () => `https://www.google.com/maps/search/?api=1&query=${this.name.replaceAll(" ", "+")}+${this.address.replaceAll(" ", "+")}+${this.city}+${this.state}+${this.zip}`
    appleMap = () => `https://maps.apple.com/?q=${this.name.replaceAll(" ", "+")}+${this.address.replaceAll(" ", "+")}+${this.city}+${this.state}+${this.zip}`
}

export const venueList = [
    new Venue("atx-abgb", "ABGB", "free", "Central Austin", "All Ages", "1305 W Oltorf St", "Austin", "TX", 78704),
    new Venue("atx-acl-live", "ACL Live", "tickets only", "Central Austin", "All Ages", "310 W Willie Nelson Blvd", "Austin", "TX", 78701),
    new Venue("atx-broken-spoke", "Broken Spoke","$10 Weekdays, $15 Weekends (Under 21 +$3) ", "Central Austin", "All Ages w/ guardian","3201 S. Lamar Blvd","Austin", "TX", 78704),
    new Venue("atx-central-market-north", "Central Market North","free", "North Central Austin", "All Ages","4001 N. Lamar Blvd", "Austin", "TX", 78756),
    new Venue("atx-continental-club", "Continental Club", "$10 most nights", "South Central Austin", "21+", "1315 S Congress Ave", "Austin", "TX", 78704),
    new Venue("atx-coral-snake", "Coral Snake", "$10+", "Central Austin", "21+", "1910 E Cesar Chavez St", "Austin", "TX", 78702),
    new Venue("atx-cotton-country-club", "Cotton Country Club", "$10-$15", "Hour away from Austin", "All Ages w/ guardian","212 E Davilla St", "Granger", "TX", 76530),
    new Venue("atx-coupland", "Coupland Dance Hall", "$10-15", "45 minutes from Austin", "21+", "101 Hoxie # 103","Coupland", "TX", 78615),
    new Venue("atx-donns-depot", "Donn's Depot", "Free before holidays, $10 cover for holidays", "Central Austin", "21+", "1600 W 5th St", "Austin", "TX", 78703),
    new Venue("atx-duetts", "Duetts", "Free on Thursdays", "40 minutes from Austin", "21+", "420 Main St", "Martindale", "TX", 78655),
    new Venue("atx-gueros", "Gueros", "free", "Central Austin", "All Ages", "1412 S Congress Ave", "Austin", "TX", 78704),
    new Venue("atx-highball", "Highball", "free", "Central Austin", "21+", "1120 S. Lamar Blvd", "Austin", "TX", 78704),
    new Venue("atx-highnoon", "High Noon", "free", "Central Austin", "21+", "2000 E Cesar Chavez St,", "Austin", "TX", 78702),
    new Venue("atx-hole-wall", "Hole in the Wall", "?", "Central Austin", "21+", "2538 Guadalupe St", "Austin", "TX", 78705),
    new Venue("atx-jos-hot-coffee", "Jo's Hot Coffee", "free", "Central Austin", "All Ages", "1300 S Congress Ave", "Austin", "TX", 78704),
    new Venue("atx-king-bee", "King Bee", "free", "Central Austin", "21+", "1906 E 12th St", "Austin", "TX", 78702),
    new Venue("atx-little-longhorn", "Little Longhorn", "free", "North Central Austin", "All Ages w/ guardian","5434 Burnet Rd", "Austin", "TX", 78756),
    new Venue("satx-lonesome-rose", "Lonesome Rose", "$20", "Central San Antonio", "21+","2114 N St Mary's St", "San Antonio", "TX", 78212),
    new Venue("atx-luckenbach", "Luckenbach", "$10+", "75 minutes from Austin", "All Ages w/ guardian","412 Luckenbach Town Loop", "Luckenbach", "TX", 78624),
    new Venue("atx-mavericks-buda", "Mavericks Buda", "$10+", "25 minutes from Austin", "21+", "275 Old San Antonio Rd", "Buda", "TX", 78610),
    new Venue("atx-mercer", "Mercer Dance Hall", "$10+", "35 minutes from Austin", "21+", "23490 Ranch Road 12", "Driftwood", "TX", 78619),
    new Venue("atx-sagebrush", "Sagebrush", "$5-10 except special events", "South Central Austin", "21+", "5500 S Congress Ave", "Austin", "TX", 78745),
    new Venue("atx-sams-town-point", "Sam's Town Point", "$10 weekdays/$15 weekends except special shows", "Far South Austin", "21+","2115 Allred Dr", "Austin", "TX", 78748),
    new Venue("atx-scottish-rite", "Scottish Rite Theater", "tickets only", "North Central Austin", "All Ages","207 W 18th St", "Austin", "TX", 78701),
    new Venue("atx-sundown", "Sundown", "Unknown", "75 minutes from Austin", "21+","909 Bessemer Ave", "Llano", "TX", 78643),
    new Venue("atx-whitehorse", "Whitehorse", "$5 weekdays, $10 Fri/Sat", "Central Austin", "21+","500 Comal St", "Austin", "TX", 78702),
]

export const venueMap = venueList.reduce((acc, venue) => {
    acc[venue.key] = venue
    return acc;
}, {})

export const getByKey = (venueKey) => venueMap[venueKey]